<template>
  <div class="booking-load-booked">
    <article class="booking-load-booked__container">
      <section>
        <b-iconstack font-scale="5">
          <b-icon stacked icon="circle" variant="info" scale="1.5"></b-icon>
          <b-icon
            stacked
            icon="envelope-open-fill"
            variant="secondary"
            scale="0.75"
            animation="throb"
          ></b-icon>
        </b-iconstack>
      </section>
      <section>
        <h2 class="booking-load-booked__subtitle">Thanks!</h2>
        <p>
          The load was booked, please, check other load. <br />
          You will soon receive an email from us.
        </p>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  name: 'LoadBook',
};
</script>

<style lang="scss" scoped>
.booking-load-booked {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border: 1px solid $color-primary-company;
    border-radius: 15px;
    background-color: $color-background-light;
    padding: 20px 20px;
    color: $color-background-dark;
    width: 75%;
  }
  &__subtitle {
    color: $color-text-light;
    font-weight: 600;
    margin: 20px 0px;
  }
}
</style>
